<template>
  <section class="page-turkiye">
    <div class="banner">
      <img
        class="logo"
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/logo.png"
      />
      <div class="title">
        <span>Cornerstone Real Estate Investment Fund </span>
        <div class="line"></div>
      </div>
    </div>
    <div class="intro">
      <div class="text" :class="{ fold: isFold }">
        <p>
          Turkish Cornerstone Real Estate Investment Fund is a legally
          established fund, according to the Turkish Citizenship Act, to obtain
          Turkish citizenship, you should buy at least USD 500,000 or equivalent
          foreign currency of real estate investment fund share or venture
          capital investment fund share with the condition that they cannot be
          sold for at least three years, as attested by the Capital Markets
          Board of Türkiye.
          <br />
          <br />
          According to the 2014 law stipulated by the Turkish Capital Market
          Board, at least 80% of the total value of the fund must be invested in
          real estate, and the fund can earn income by selling, buying, and
          leasing real estate. Moreover, Turkish Cornerstone Real Estate
          Investment Fund can only invest in mature properties, and are not
          allowed to invest in projects under construction, except for projects
          under construction of Turkish state-owned enterprises.
        </p>
      </div>
      <div class="look-more">
        <div class="opacity" v-if="isFold">
         
        </div>
        <div class="btn" @click="handleLookMore">
          <span>{{ btnText }}</span>
          <img
            :src="
              isFold
                ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
                : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
            "
          />
        </div>
      </div>
      <div class="book">
        <div class="left">
          <img
            class="book1"
            src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/b1.png"
          />
          <img
            src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/b2.png"
          />
        </div>
        <div class="right">
          <img
            src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/b3.png"
          />
        </div>
      </div>
      <div class="bottom">Fund Certificate Approved by CMB</div>
    </div>

    <div class="part">
      <div class="title">
        <span class="van-hairline--bottom">
          Advantages of Cornerstone REIF</span
        >
      </div>
      <div class="list">
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/1.png"
            />
          </div>
          <div class="name">
            The fund is established according to the legal basis, recognized by
            the Turkish Immigration Act and approved by the Capital Markets
            Board of Türkiye;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/2.png"
            />
          </div>
          <div class="name">
            The fund has a mature and experienced management team to provide
            professional services;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/3.png"
            />
          </div>
          <div class="name">
            The funds is regulated by CMB, and professional depository banks
            will ensure the safety of funds;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/4.png"
            />
          </div>
          <div class="name">
            The fund is a standardized product with clear and transparent fees,
            no taxes and additional costs;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/5.png"
            />
          </div>
          <div class="name">
            The fund can diversify investment risks, and the investment field is
            mature and interest-bearing real estate, which can realize the
            diversification of asset allocation;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/6.png"
            />
          </div>
          <div class="name">
            The fund does not use financing leverage and has no debt;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/7.png"
            />
          </div>
          <div class="name">
            The fund is exempt from capital gains tax, withholding tax,
            corporate income tax;
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/8.png"
            />
          </div>
          <div class="name">
            The fund has a clear exit mechanism, which can lock in the
            counterparty in advance, and the immigration does not need to be
            bound to the fund investment.
          </div>
        </div>
      </div>
      <div class="title">
        <span class="van-hairline--bottom">
          Investment Strategy of Cornerstone REIF</span
        >
      </div>
      <div class="box">
        <div class="item">
          <div class="name">Target investment field</div>
          <div class="desc">
            High quality commercial assets such as the entire hotel
          </div>
        </div>
        <div class="item">
          <div class="name">Target investment strategy</div>
          <div class="desc">Focus on mature properties in core areas</div>
        </div>
        <div class="item">
          <div class="name">Utilization rate of funds</div>
          <div class="desc">
            The Turkish Fund Act requires at least 80% of the fund needs to be
            invested every year which helps to improve the utilization rate of
            funds
          </div>
        </div>
        <div class="item">
          <div class="name">No leverage</div>
          <div class="desc">
            The fund use 100% own capital to invest，100% of the target belongs
            to the investors
          </div>
        </div>
      </div>
    </div>
    <div class="datestatic">
      <div class="title">
        <span class="van-hairline--bottom"> Elements of Cornerstone REIF </span>
      </div>
      <div class="img">
        <img
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/table1.png"
        />
      </div>
      <div class="img">
        <img
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/table2.png"
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isFold: true,
      btnText: "View more",
    };
  },
  beforeCreate() {
    document.title = "Fund in Turkiye";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View more";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-turkiye {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/bg.png")
      no-repeat center center / cover;
    .logo {
      height: 56px;
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 20px;
      padding-bottom: 36px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }
      .line {
        width: 150px;
        border-top: 1px solid rgba(255, 255, 255, 1);
      }
    }
  }
  .intro {
    background: #fafafa;
    .text {
      padding: 20px 18px 0;
      font-weight: 400;
      font-size: 11px;
      color: #2c2c2c;
      text-align: justify;
      font-style: normal;
      &.fold {
        height: 71px;
        overflow: hidden;
      }
      p {
        font-size: 11px;
        color: rgba(38, 38, 38, 1);
        line-height: 20px;
      }
    }

    .look-more {
      padding: 20px 18px;
      background: #fafafa;
      z-index: 10;
      .opacity {
        position: absolute;
        height: 20px;
        width: calc(100% - 36px);
        margin: -40px 0 0px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #fafafa
        );

        z-index: 10;
       
      }
      .btn {
        margin: 0 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        border-radius: 4px;
        border: 1px solid rgba(38, 38, 38, 1);
        &:active {
          opacity: 0.8;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
        }
        img {
          width: 8px;
          margin-left: 8px;
        }
      }
    }

    .bottom {
      padding: 0px 0 15px;
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      text-align: justify;
    }
    .book {
      display: flex;
      margin: 20px 18px 10px;
      .left {
        width: 50%;
        margin-left: 18px;
        .book1 {
          margin-bottom: 23px;
        }
      }
      .right {
        width: 50%;
        margin: 0 20px;
      }
      img {
        width: 100%;
      }
    }
  }

  .part {
    padding: 25px 0 0;
    .title {
      padding-bottom: 10px;
      text-align: center;
      span {
        margin-top: 0px;
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .list {
      margin: 18px;
      background: #f3f8ff;
      border-radius: 8px;
      background-image: url(//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/jwp.png);
      background-repeat: no-repeat; /* 确保背景图片不重复 */
      background-position: right bottom; /* 将背景图片固定在右下角 */
      .item {
        padding: 10px;
        display: flex;
        align-items: center;
        .icon {
          margin-right: 10px;
          img {
            width: 18px;
          }
        }
        .name {
          flex: 1;
          font-weight: 400;
          font-size: 11px;
          color: #2c2c2c;
          line-height: 15px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
    .box {
      margin: 18px 18px 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 165px;
        margin-bottom: 10px;
        background: #f3f8ff;
        border-radius: 8px;
        .name {
          margin: 10px 10px 5px;
          font-weight: 500;
          font-size: 12px;
          color: #0462c9;
          line-height: 17px;
          text-align: center;
          font-style: normal;
        }
        .desc {
          font-weight: 400;
          font-size: 11px;
          color: #2c2c2c;
          line-height: 13px;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
  .datestatic {
    padding: 25px 18px;
    background: #fafafa;
    .title {
      padding-bottom: 10px;
      text-align: center;
      span {
        margin-top: 15px;
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .img {
      margin: 20px 0;
    }
    img {
      width: 100%;
    }
  }
}
</style>